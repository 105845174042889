/* Grid Settings */
$spacer:					        1em;
$enable-flex:           	true;
$main-sm-columns:			    12;
$sidebar-sm-columns:		  6;
$grid-gutter-width-base:	20px;
$container-padding:       5.5555%;

/* Colours */
$bg-color:                #FFF7F0;
$brand-primary:				    #2F4A23;
$text-color:				      #2F4A23;
$dark-color:				      #808080;
$light-color:				      #b2b2b2;
$link-color:				      #000;
//$link-hover-color:      #999;
$link-hover-decoration: 	underline;
$border:					        1px solid #ccc;
$alt-bg:					        #f4faf9;

/* Typography */
//$font-light:				    'Larsseit', Helvetica, Arial, sans-serif;
//$font-regular:				  'Larsseit', Helvetica, Arial, sans-serif;
//$font-bold:					    'Larsseit', Helvetica, Arial, sans-serif;
$font-family-base:        'Roboto Mono', 'Lucida Console', Monaco, 'Courier New', monospace;
$font-family-monospace:   'Roboto Mono', 'Lucida Console', Monaco, 'Courier New', monospace;
$font-family-sans-serif:	'Larsseit', Helvetica, Arial, sans-serif;
$line-height-base:          1.462;

/* Navbar */
$navbar-default-link-color:			    $text-color;
$navbar-default-link-hover-color:	  $dark-color;
$navbar-default-link-active-color:  $dark-color;

/* MIX INS */

@mixin text-indent {
  text-indent:  100%;
  white-space: 	nowrap;
  overflow: 		hidden;
  font-size: 		1px;
}