	.slide {
		background-color: #fff;
		transition: background-color 0.4s ease-out;
	}
	.barba-container {
		min-height: 100vh;
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition: all 0.4s ease-out;
	}

	.slide .barba-container {
		opacity: 0;
		background-color: #fff;
	}

	.blog.slide .barba-container {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	.single-post.slide .barba-container {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

/** BLOG **/
	.blog,
	.single-post {
		h2 {
			font-size: 1.538em;
		}
		h3 {
			letter-spacing: 0;
			padding-bottom: 0.25em;
		}
		.post-meta {
			font-size: 10px;
			font-weight: 300;
			line-height: 1.333;
			margin-top: 1.75em;
			margin-bottom: 0.5rem;
			letter-spacing: 1px;
			display: block;
		  i {
				float: right;
				font-size: 1.5em;
				margin-top: -2px;
			}
		}
		.get-post-meta {
			display: none;
		}
		.grid-item.format-image p + a {
			display: none;
		}
		a {
			text-decoration: none;
		}
		.grid-item p {
			font-size: 11px;
			font-weight: 400;
			line-height: 15px;
			margin: 0;
			&:last-of-type {
				padding: 0 1em 2.5em 0;
			}
		}
		.grid-item.format-standard p {
			padding: 0 1em 2.5em 0;
		}
		.grid-item.format-image p:last-of-type {
			padding-top: 0.2em;
		}
		img {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	.simplebar-track.simplebar-vertical,
	.simplebar-track.simplebar-horizontal {
		display: none;
	}

	.modal-open .simplebar-track.simplebar-vertical {
		display: block;
	}

/** SINGLE POST **/
	.single-post {
		overflow: hidden;
		background: #fff;
	}
	.single-post.fullsize {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		z-index: 1050;
		background-color: #f1d2c1;
		background: -moz-linear-gradient(left, #f4dbcd 0%, #f4dbcd 50%, #f1d2c1 50%, #f1d2c1 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, #f4dbcd 0%,#f4dbcd 50%,#f1d2c1 50%,#f1d2c1 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, #f4dbcd 0%,#f4dbcd 50%,#f1d2c1 50%,#f1d2c1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4dbcd', endColorstr='#f1d2c1',GradientType=1 ); /* IE6-9 */
	}

	.single-post.fullsize article,
	.single-post.fullsize aside {
		position: fixed;
		top: 0;
		bottom: 0;
		height: 100vh;
		z-index: 1051;
	}

	.single-post.fullsize article {
		left: 0;
		right: 0;
		width:100%;
		background-color: rgb(244, 219, 205);
		padding: 6.2% 7.75%;
	}

	.single-post.fullsize aside {
		left: 50%;
		right: 0;
		background-color: #f1d2c1;
		padding: 7.75% 10.5%;
		display: none;
	}

	.single-post {
		.close {
			z-index: 1052;
		}
		h2 {
			letter-spacing: 0;
			padding-bottom: 0.5em;
		}
		.entry-content {
			.post-meta {
				margin-top: 0;
			}
			p {
				margin-bottom: 1.5em;
			}
			img {
				padding-bottom: 3em;
			}
			.flexslider img {
				padding: 0;
			}
		}
	}

	/* simple bar */
  .modal-open,
	.single-post {
		[data-simplebar] {
			position: relative;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-content: flex-start;
			align-items: flex-start;
			width: inherit;
			height: inherit;
			max-width: inherit;
			max-height: inherit;
		}

		.simplebar-wrapper {
			overflow: hidden;
			width: inherit;
			height: inherit;
			max-width: inherit;
			max-height: inherit;
		}

		.simplebar-mask {
			direction: inherit;
			position: absolute;
			overflow: hidden;
			padding: 0;
			margin: 0;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			width: auto !important;
			height: auto !important;
			z-index: 0;
		}

		.simplebar-offset {
			direction: inherit !important;
			box-sizing: inherit !important;
			resize: none !important;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			padding: 0;
			margin: 0;
			-webkit-overflow-scrolling: touch;
		}

		.simplebar-content {
			direction: inherit;
			box-sizing: border-box !important;
			position: relative;
			display: block;
			height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
			width: auto;
			visibility: visible;
			/* overflow: scroll !important; Scroll on this element otherwise element can't have a padding applied properly */
			overflow-x: hidden !important;
			overflow-y: scroll !important;
			max-width: 100%; /* Not required for horizontal scroll to trigger */
			max-height: 100%; /* Needed for vertical scroll to trigger */
		}

		.simplebar-placeholder {
			max-height: 100%;
			max-width: 100%;
			width: 100%;
			pointer-events: none;
			display: block;
		}

		.simplebar-height-auto-observer-wrapper {
			box-sizing: inherit !important;
			height: 100%;
			width: inherit;
			max-width: 1px;
			position: relative;
			float: left;
			max-height: 1px;
			overflow: hidden;
			z-index: -1;
			padding: 0;
			margin: 0;
			pointer-events: none;
			flex-grow: inherit;
			flex-shrink: 0;
			flex-basis: 0;
		}

		.simplebar-height-auto-observer {
			box-sizing: inherit;
			display: block;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			height: 1000%;
			width: 1000%;
			min-height: 1px;
			min-width: 1px;
			overflow: hidden;
			pointer-events: none;
			z-index: -1;
		}

		.simplebar-track {
			z-index: 1;
			position: absolute;
			right: 0;
			bottom: 0;
			pointer-events: none;
		}

		.simplebar-scrollbar {
			position: absolute;
			right: 10px;
			width: 7px;
			min-height: 10px;
		}

		.simplebar-scrollbar:before {
			position: absolute;
			content: "";
			background: rgb(71, 71, 71);
			border-radius: 7px;
			left: 0;
			right: 0;
			opacity: 0;
			transition: opacity 0.2s linear;
		}

		.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
			/* When hovered, remove all transitions from drag handle */
			opacity: 0.15;
			transition: opacity 0s linear;
		}

		.simplebar-track.simplebar-vertical {
			top: 10px;
			bottom: 10px;
			width: 4px;
		}

		.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
			top: 2px;
			bottom: 2px;
		}

		.simplebar-track.simplebar-horizontal {
			left: 0;
			height: 4px;
		}

		.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
			height: 100%;
			left: 2px;
			right: 2px;
		}

		.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
			right: auto;
			left: 0;
			top: 2px;
			height: 7px;
			min-height: 0;
			min-width: 10px;
			width: auto;
		}

		/* Rtl support */
		[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
			right: auto;
			left: 24px;
		}

		.hs-dummy-scrollbar-size {
			direction: rtl;
			position: fixed;
			opacity: 0;
			visibility: hidden;
			height: 500px;
			width: 500px;
			overflow-y: hidden;
			overflow-x: scroll;
		}
	}
	.simplebar-content {
		overflow: hidden !important;
	}
	.simplebar-placeholder {
		display: none;
	}

		/* POST NAV */
		.posts-navigation {
			font-family: $font-family-sans-serif;
			font-size: 1.308em;
			padding-top: 0.8%;
			padding-bottom: 1.5%;
			p {
				padding-top: 1.5%;
			}
			a {
				text-decoration: none;
			}
		}

		.posts-navigation p:last-child,
		.posts-navigation .nav-previous {
			text-align: right;
			float: right;
		}

		.malinky-ajax-pagination-loading img {
			width: 24px;
			height: 24px;
			display: none;
		}
		.malinky-ajax-pagination-loading,
		.malinky-load-more {
			padding: 2em 0 0;
			text-align: center;
			font-family: $font-family-monospace;
			font-size: 0.824em;
			a {
				color: #C0C0C0;
				transition: color 250ms;
				.modal-open & {
					color: #777;
				}
				&:hover {
					color: $text-color;
				}
			}
		}

		/* MODAL/AJAX */
		.load-article-wrapper {
			background-color: #f1d2c1;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1040;
			overflow: hidden;
			height: 100vh;
			transform: translate3d(100%, 0, 0);
			transition: transform 600ms ease;
			.modal-open & {
				transform: translate3d(0, 0, 0);
			}
			a.close {
				z-index: 1500;
			}
		}


		/* MEDIA QUERIES */

		@media screen and (min-width: 1500px) {

			.blog {
				.grid-item {
					min-height: 460px;
				}
			}

		}

		@media screen and (min-width: 1800px) {

			.blog {
				.grid-item {
					min-height: 500px;
				}
			}

		}

		/* Desktop // bootstrap extra large devices 1200px and up ----------- */
		@include media-breakpoint-up(xl) {}

		/* Laptop // bootstrap large devices less than 1200px ----------- */
		@include media-breakpoint-down(lg) {}

		/* Laptop // bootstrap large devices 992px or more ----------- */
		@include media-breakpoint-up(lg) {

			.blog {
				.main {
					min-height: 90vh;
				}
				.grid-item {
					min-height: 420px;
				}
				&.modal-open .grid-item {
					min-height: auto;
					padding-bottom: 1.2em;
				}
			}

			#blog-row {
				position: relative;
				left: 0;
				transform: translate3d(0, 0, 0);
				transition: transform 0.6s ease;
			}

			.modal-open #blog-row {
				transform: translate3d(-100%, 0, 0);
				transition: transform 0.6s ease;
			}

			.modal-open.modal-slide-complete #blog-row {
				z-index: 2000;
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				left: 50%;
				width: 50%;
				margin: 0;
				padding: 7.75% 10%;
				opacity: 0;
				transform: none;
				transition: none;
				animation: fade 400ms ease forwards;
				.col-lg-4 {
					-ms-flex: 0 0 100%;
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
			.modal-fade-complete #blog-row {
				transform: translate3d(-100%, 0, 0);
				transition: none;
			}

			@keyframes fade {
				to {
					opacity: 1;
				}
			}
			@keyframes slide {
				to {
					transform: translate3d(0, 0, 0);
				}
			}

			.single-post.fullsize article {
				right: 50%;
				width: 50%;
			}

			.single-post.fullsize aside {
				display: block;
			}

			/* GRID */
			.grid {
				h3 {
					margin: 0.75em 3em 0.6em 0;
					min-height: 105px;
				}

				.more {
					padding: 0.55em 0 0.75em;
					opacity: 0;
					transition: opacity 300ms;
				}

				a:hover .more {
					opacity: 1;
				}
			}

			/* Sidebar */
			.single aside p {
				margin-bottom: 1.2em !important;
			}
			.single aside a:hover {
				color: $text-color;
				border-color: $text-color;
				transition: all 250ms;
			}

		}

		/* Tablet // bootstrap medium devices less than 992px ----------- */
		@include media-breakpoint-down(md) {

			#blog-row {
				transform: translate3d(0,0,0);
				transition: transform 600ms ease-out;
			}

			.modal-open #blog-row {
				transform: translate3d(-100%,0,0);
			}

			.single-post.fullsize {
				background: #f4dbcd;
			}

			.single-post.fullsize article {
				padding: 4em 1.538em;
			}

			.single-projects {
				h3 {
					font-size: 1.462em;
				}
				.smaller.dark {
					padding-top: 0.8em;
					padding-bottom: 0.5em;
					a {
						border: none;
					}
				}
				aside {
					padding-bottom: 0.25em;
				}
			}

			.blog .grid-item {
				padding-bottom: 1.2em;
			}

			.blog h3,
			.single-post h3 {
				padding: 0;
			}

			.blog .post-meta,
			.single-post .post-meta {
				margin: 1em 0 0.25rem;
			}

			#post-content h2 {
				font-size: 1em;
				margin-bottom: 1em;
			}

			.single-post .entry-content img {
				padding-bottom: 2.5em;
			}
			.single-post .entry-content .flexslider img {
				padding-bottom: 0.5em;
			}

		}

		/* Tablet // bootstrap medium devices 768px and up ----------- */
		@include media-breakpoint-up(md) {}

		/* Mobile (landscape) // bootstrap small devices less than 768px ----------- */
		@include media-breakpoint-down(sm) {

			.portrait {
				padding-right: 10px;
			}
			.portrait+.portrait {
				padding: 0 20px 0 10px;
			}

			.posts-navigation > div {
				padding: 0 12px;
			}
			.view {
				margin: 8% auto 7.5% auto;
				text-align: center;
			}

	}

	@media (hover: hover) {

		.blog .grid-item a {
			img {
				opacity: 1;
				-webkit-backface-visibility: hidden;
				transition: opacity 300ms;
			}
			&:hover img {
				opacity: 0.85;
			}
		}

	}
