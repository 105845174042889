/** GENERAL **/
.page-template-template-work section.container > .row {
	min-height: 0;
}

.page-title h2 {
	padding-bottom: 1em;
}

section.split {
	table img {
		padding: 10%;
	}
}

.split .txt .container ul li,
.text ul li,
.accordion ul li {
	margin-left: 1em;
}
.split .txt .container ul li:before,
.text ul li:before,
.accordion ul li:before {
	content: "– ";
	padding-right: 0.5em;
	margin-left: -1em;
	display: inline-block;
}

section.text {
	text-align: center;
}

section.image {
	width: auto;
	max-width: 100%;
	text-align: center;
}

.bg-img {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	align-self: stretch;
	position: relative;
}

section.img-grid img {
	margin-top: 16px;
	margin-bottom: 16px;
}

section.img-grid .img1 {
	box-shadow: inset 0px -10px 0px 0px #fff7f0;
}

section.img-grid .img2 {
	box-shadow: inset 0px 10px 0px 0px #fff7f0;
}

/** HOME **/
body.home {
	overflow: hidden;
}

.home .desc {
	font-weight: 400;
	position: absolute;
	bottom: 3.8%;
	left: 0;
	width: 50%;
	padding-left: 5.555%;
	padding-right: 5.555%;
	z-index: 100;
	transition: color 250ms;
}

.home .desc b,
.home .desc strong {
	display: inline-block;
	padding-bottom: 0.5em;
	letter-spacing: 1px;
}

.proj-desc {
	font-family: $font-family-monospace;
	color: #fff !important;
	text-decoration: none !important;
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	text-align: right;
	margin: 0;
	padding: 7% 6.8%;
	z-index: 1000;
	background: transparent;
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
}

.home .proj-desc {
	padding: 7% 6.8%;
}

.section.img-grid .proj-desc {
	right: auto;
	text-align: left;
	padding-left: 5%;
	padding-right: 5%;
	padding-bottom: 4.5%;
}

.section.img-grid .img1 .proj-desc {
	margin-bottom: 10px;
}

.section.image .proj-desc {
	padding: 3.5% 3.4%;
}

.section.image .container .proj-desc {
	padding: 2.25% 10%;
	text-align: center;
	background: transparent;
}

.proj-desc.dark {
	color: $text-color !important;
}

.proj-desc p {
	font-weight: 400;
	font-size: 11px;
	margin: 0;
}

/** WORK **/
.grid {
	a {
		display: block;
		margin: 10px 0;
	}
	img {
		padding: 0;
	}
}

#portfolio-caption {
	font-family: $font-family-sans-serif;
	font-size: 1.538em;
	display: inline-block;
	position: absolute;
	z-index: 100;
	color: #fff;
	white-space: nowrap;
}

.page-template-template-work .page-nav {
	display: none;
}

.single-projects .split blockquote {
	margin-bottom: 3.5em;
}

.single-projects .split .colophon {
	margin-top: 2.5em;
}

.credit {
	font-family: $font-family-sans-serif;
	font-size: 1.538em;
	text-align: center;
	padding-top: 1.8em;
	.logo-white &,
	section.image.fullwidth & {
		color: #fff;
	}
	.logo-orange & {
		color: #CD6440;
	}
	.posts-navigation & {
		font-size: 1.175em;
		width: 100%;
		padding: 2em 0;
		color: $brand-primary;
	}
}

.single-projects .page-nav {
	display: none;
}

/* work filter */
#project-filter {
	margin-bottom: 1.75%;

	/*filter-title*/
	.filter-title {
		margin-bottom: 18px;
		transition: margin 0.8s linear;
		position: relative;
		height: 42px;

		a {
			display: block;
			text-decoration: none;
			font-size: 0.846em;
			padding: 0.65em 0 0.65em;
			&:hover {
				text-decoration: underline;
			}
		}

		span {
			display: inline-block;
		}

		button {
			display: none;
			top: 6px;
			right: auto;
			text-decoration: none;
			outline: none;
		}
	}

	/*filter-lists*/
	.toggle-filter {
		display: none;
		width: 100%;
		overflow: hidden;
		padding-top: 1%;

		.expand {
			opacity: 			0;
			transition: 		opacity 500ms ease-in-out 200ms;
		}
	}

	ul {
		padding-bottom: 		1.9em;

		li {
			font-family: $font-family-monospace;
			font-size: 11px;
			line-height: 1.385;
			padding: 0;
		}

		a {
			padding-bottom: 	6px;
			text-decoration: none;
			display: block;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	&.active {
		margin-bottom: 0;
		.filter-title {
			margin-bottom: 0;
		}
		.toggle-filter {
			.expand {
				opacity: 1;
			}
		}
	}
}

.main .filter-group li {
	font-size: 				1em;
	padding: 				0 0 10px 0;
}


/** PEOPLE **/
.page-template-template-people blockquote a {
	text-decoration: none;
}
.page-template-template-people .grid {
	padding-top: 3%;
	padding-bottom: 5%;
}
.page-template-template-people .grid-item p {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	line-height: 1.35;
	margin-bottom: 0;
	padding: 4em 0.75em 1.3em 1em;
	color: #fff;
	background: transparent;
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%);
	span {
		text-transform: uppercase;
	}
}

/* modal */

.row.page-title,
.row.grid,
.row.text,
header.navbar,
footer.content-info {
	transform: translate3d(0, 0, 0);
	transition: transform 0.6s ease;
}
.modal-open .row.page-title,
.modal-open .row.grid,
.modal-open .row.text,
.modal-open header.navbar,
.modal-open footer.content-info {
	transform: translate3d(-100%, 0, 0);
	transition: transform 0.6s ease;
}
.modal-backdrop.show {
	opacity: 0;
}
.single-people,
.modal {
	background-color: #FFEEB8;
	display: block !important;
	padding: 0 !important;
	transform: translate3d(100%, 0, 0);
	transition: transform 0.6s ease;
	h4 {
		margin-top: 1.9em;
	}
	img {
		padding: 0 0 5%;
	}
	button.close {
		right: 5.5%;
		top: 1.25%;
		z-index: 100;
	}
}
.single-people {
	transform: none;
	button.close {
		display: none;
	}
}
.modal.show {
	overflow-y: scroll;
	transform: translate3d(0, 0, 0);
	transition: transform 0.6s ease;
}
.modal-dialog {
	max-width: 100%;
	width: 100vw;
	height: 100vh;
	margin: 0;
}
.modal-content {
	background: none;
	border: none;
	border-radius: 0;
	min-height: 100%;
}
.modal-header {
	border-bottom: none;
	padding: 0;
}
.modal-body {
	padding: 11.5% 5.55%;
	display: flex;
}
	
/* GRID LAYOUTS */
  
.grid {
	a {
		display: block;
		margin: 10px 0;
		position: relative;
	}
	.page-template-template-people & a {
		transition: opacity 250ms;
		img {
			opacity: 1;
			-webkit-backface-visibility: hidden;
			transition: opacity 350ms ease-in-out;
		}
		&:hover img {
			opacity: 0.85;
		}
	}
	img {
		padding: 0;
	}
}


/* NEWS */
 .blog .grid {

    article {

      &:not(.has-post-thumbnail) {  
	    a.image {
		  .hover {
		  	opacity: 1;
		  	position: absolute;
		  }
		}
	  }

	  a.image {
		.hover {
		  top: 0;
		  padding: 1.5em 1.25em;
		}
	  }

  	}
  }


/** MEDIA QUERIES **/

@media screen and (hover: hover), screen and (min-width: 992px) {

	.page-template-template-people blockquote a {
		&.active,
		&:hover {
			text-decoration: underline;
		}
	}

}

@media screen and (hover: none), screen and (max-width: 991px) {

	.page-template-template-people blockquote a {
		text-decoration: underline;
	}

	.page-template-template-people .page-title .small {
		padding: 1.75em 0 0;
		a {
			font-size: 11px;
			text-decoration: none;
		}
	}

}

	/* Mobile (landscape) // bootstrap small devices less than 768px ----------- */
	@include media-breakpoint-down(sm) {}

	/* Tablet // bootstrap medium devices less than 992px ----------- */
	@include media-breakpoint-only(md) {}

	@include media-breakpoint-down(md) {

		.split {
			box-shadow: none !important;
		}

		.split .container {
			padding-top: 5%;
			padding-bottom: 5%;
			table td:first-child img {
				padding-left: 0;
			}
			table td:last-child img {
				padding-right: 0;
			}
		}

		.split .section-mobile,
		.text .section-mobile,
		.image .section-mobile,
		.img-grid > .row {
			min-height: 90vh;
			display: flex;
			& > .container {
				align-self: center;
			}
			.col-sm-12 {
				margin: 0 !important;
			}
		}

		section.img-grid {
			.container {
				padding: 11.111% 5.5555% !important;
			}
			img {
				margin-bottom: 10px;
			}
			.rows img {
				margin-top: 15px;
				margin-bottom: 15px;
			}
		}

		.half .txt,
		.half .bg-img {
			height: 50vh;
			display: flex;
			.container {
				align-self: center;
			}
		}

		.single-projects .half .bg-img {
			height: 33.5vh;
		}
		
		/* HOME */
		.home .main .row > div {
			height: 50vh;
		}
		
		.home .desc,
		.proj-desc p {
			display: none;
		}

		.home .proj-desc {
			right: auto;
			bottom: auto;
			top: 0;
			left: 0;
			padding: 5.55%;
			text-align: left;
			background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
			background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
			background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
		}

		.home h1 {
			margin-top: 22vh;
		}


		/* WORK */
		.page-template-template-work blockquote {
			font-size: 1.154em;
			line-height: 1.533;
		}

		.page-template-template-work a {
			text-decoration: none;
		}

		.page-template-template-work .grid h3 {
			letter-spacing: 0;
			font-size: 11px;
			text-transform: none;
			padding: 0.7em 0 0.65em;
		}

		.page-template-template-work #project-filter h3 {
			text-transform: uppercase;
		}

		#project-filter {
			margin-bottom: 0;
			ul {
				padding-bottom: 0;
				margin-bottom: 0.5em;
			}
			.filter-title {
				height: 26px;
				margin-bottom: 0;
				a {
					line-height: 1;
					padding: 0;
				}
				button {
					top: -7px;
				}
			}
		}

		/* single projects */
		.single-projects .split:first-child > .row {
			flex-direction: column-reverse;
		}

		.single-projects .split blockquote {
			margin-bottom: 20%;
		}

		.single-projects .split .colophon {
			margin-top: 0;
		}

		.credit {
			font-size: 1em;
			padding-top: 4em;
		}

		.img-padding {
			padding: 0;
		}

		/* PEOPLE */
		.page-template-template-people .grid {
			padding-bottom: 10%;
		}

		.page-template-template-people .grid-item p {
			font-size: 11px;
		}

		.page-template-template-people blockquote a {
			opacity: 0.4;
			&.active {
				opacity: 1;
			}
		}

		.page-template-template-people article .hidden-lg-up p {
			font-size: 11px;
		}

		.modal h2 {
			font-size: 0.846em; /*11px*/
			padding-bottom: 1.25em;
			letter-spacing: 0;
		}

		#project-filter h3 {
			padding-top: 0;
		}

	}

	/* Tablet // bootstrap devices 768px and over ----------- */
	@include media-breakpoint-up(md) {


	}

	/* Laptop // bootstrap large devices 992px and over ----------- */
	@include media-breakpoint-up(lg) {

		.page-template-template-work .content {
			padding-top: 12.8%;
		}

		section.split .container {
			padding-top: 11.11%;
			padding-bottom: 11.11%;
			padding-top: calc(44px + 14.4%);
			padding-bottom: calc(44px + 14.4%);
		}

		section.section > .row,
		.fp-tableCell > .row,
		li.container-fluid > .row {
			min-height: 100vh;
			position: relative;
		}

		section.split .row > div {
			background-color: transparent !important;
		}

		section.split h1,
		section.split blockquote {
			padding-right: 12%;
		}

		section.text blockquote {
			font-size: 2.308em; /*30px*/
			line-height: 1.367;
			max-width: 1000px;
			padding-top: 12.5%;
			padding-bottom: 12.5%;
			margin: 0 auto;
		}

		.single-projects h2.page-title {
			margin-top: 20%;
		}

		.modal-body {
			padding: 5.55% 5.55% 5.55% 10%;
		}

		.modal button.close {
			right: auto;
			left: 2.5%;
			top: 2.85%;
		}

		.modal img {
			padding: 0 0 4%;
		}

	}

	.project-footer-row {
		display: flex;
		flex-wrap: wrap;
		margin-right: -10px;
    margin-left: -10px;
	}

/* Desktop // bootstrap large devices 1200px and over ----------- */
	@include media-breakpoint-up(xl) {}