:focus {outline:none !important;}

.btn, 
.comment-form input[type=submit], 
.search-form .search-submit {
  border: 2px solid $text-color;
  padding: 0.55em 1.55em;
  margin: 0.485em 0.485em 0.485em 0;
  font-size: 0.9em;
  border-radius: 0;
}

.btn i {
  margin-right: 8px;
}

.btn i.icon {
  background-size: contain !important;
  width: 17px;
  height: 17px;
  margin-top: 3px;
  display: inline-block;
}

.back {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  display: inline-block;
  margin-top: 4px;
  margin-left: 1.9em;
  line-height: 2em;
  span {
    border: 1px solid $brand-primary;
    border-radius: 50%;
    display: inline-block;
    height: 1.9em;
    width: 1.9em;
  }
}

.logo-green .back {
  color: $brand-primary;
  span,
  .arrow:after,
  .arrow:before {
    border-color: $brand-primary;
  }
}

.logo-white .back {
  color: #fff;
  span,
  .arrow:after,
  .arrow:before {
    border-color: #fff;
  }
}

.logo-orange .back {
  color: #CD6440;
  span,
  .arrow:after,
  .arrow:before {
    border-color: #CD6440;
  }
}

@include media-breakpoint-up(lg) {

  .back {
    font-size: 12px;
    letter-spacing: 0.7px;
    span {
      height: 2.1em;
      width: 2.1em;
      margin-right: 2px;
    }
  }

}

@include media-breakpoint-down(md) {

  .back {
    margin: 0.9em -0.1em 0 2.4em;
  }

  .single-projects.menu-active .back {
    display: none;
  }

}

.scroll {
  font-family: $font-family-sans-serif;
  text-align: center;
  display: block;
}

.content-info .scroll {
  padding: 0.95em 2em 1.95em 3.5em;
  font-size: 1.615em;
  letter-spacing: 0.5px;
}

@include media-breakpoint-up(md) {


}

.arrow {
  -webkit-backface-visibility: hidden;
  padding: 0 5px;
  display: inline-block;
  position: relative;
  margin: 0 0 5px 18px;
  .back & {
    margin: 0 0 4px 13px;
    padding: 0 0 0.5em 0.4em;
  }
			
  &:before, 
  &:after {
  	content: "";
  	position: absolute;
  	display: block;
    transition: all 300ms ease-out;
  }
			
  &:before {	  
  	right: 4px;
  	top: -3px;
  	width: 15px;
  	height: 1px;
  	transform: rotate(-45deg);
  	transform-origin: right top;
  	box-shadow: inset 0 0 0 10px;
  }
  .back &:before {
    width: 8px;
  }
			
  &:after {
  	width: 8px;
  	height: 8px;
  	border-width: 1px 1px 0 0;
  	border-style: solid;
  	border-color: $brand-primary;
  	top: -3px;
  	right: 3px;
  }
  .back &:after {
    width: 5px;
    height: 5px;
  }

  &.right {
    transform: rotate(45deg);
    &.up{
      transform: none;
    }
    &.down{
      transform: rotate(90deg);
    }
  }
  &.left {
    margin: 0 18px 5px 0;
    transform: rotate(-135deg);
    &.up{
      transform: rotate(-90deg);
    }
    &.down{
      transform: rotate(180deg);
    }
  }
  &.up{
    transform: rotate(-45deg);
  }
  &.down{
    transform: rotate(135deg);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@include media-breakpoint-up(lg) {
  .arrow {
    padding: 0 0 0.5em 0.5em;
    .back & {
      margin: 0 0 4px 14px;
    }
        
    &:before {    
      right: 4px;
      top: -3px;
      width: 15px;
      height: 1px;
    }
    .back &:before {
      width: 10px;
    }
        
    &:after {
      width: 10px;
      height: 10px;
      border-width: 1px 1px 0 0;
      top: -3px;
      right: 3px;
    }
    .back &:after {
      width: 6px;
      height: 6px;
    }

    &.left {
      margin: 0 1.5em 0.3em 0;
    }

    &.right {
      margin: 0 0 -0.1em 1.5em;
    }
  }
}

/*** CLOSE ***/
.close {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  top: 14px;
  right: 18px;
  &::before, 
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    right: 0;
    margin-top: -1px;
    background: $text-color;
  }
  .filter-title & {
    width: 21px;
  }
  .filter-title &::before,
  .filter-title &::after {
    height: 2px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

@include media-breakpoint-up(lg) {
  .close {
    opacity: 1;
    width: 28px;
    height: 28px;
    top: -2px;
    right: 21px;
    cursor: pointer;
    transform: rotate(0);
    -webkit-backface-visibility: hidden;
    border: none;
    &:hover {
      opacity: 1;
      transform: rotate(90deg);
      transition: all 300ms ease-in-out;
    }
    &::before, 
    &::after {
      height: 3px;
    }
  }

  .blog .close,
  .single-post .close {
    top: 0;
    left: 0;
    margin: 2% 1% 1% 2.6%;
    right: auto;
    width: 26px;
    height: 26px;
  }
}

@include media-breakpoint-down(md) {

  .blog .close,
  .single-post .close {
    background: #f4dbcd;
    padding: 16px;
    border-radius: 50%;
    &:before,
    &:after {
      width: 60%;
      right: 18%;
    }
  }

}

@media screen and (max-width: 320px) {
  .back {
    margin-left: 1em;
    font-size: 10px;
    .arrow {
      margin: 0 0 4px 12px;
    }
  }
}

@media (hover: hover) {

  .btn:hover {
    border: 2px solid $text-color;
    background-color: #f2f2f2;
    text-decoration: none;
    transition: all 300ms;
  }

  a:hover .arrow {
    animation: fadeInOut 1.25s infinite;
  }

}