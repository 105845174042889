/*
 * jQuery FlexSlider v2.6.3
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */
/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/
@font-face {
  font-family: 'flexslider-icon';
  src: url('fonts/flexslider-icon.eot');
  src: url('fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('fonts/flexslider-icon.woff') format('woff'), url('fonts/flexslider-icon.ttf') format('truetype'), url('fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover {
  outline: none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flexslider ul,
.flexslider li {
  font-size: 1em;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  padding: 0 0 1.84em 0;
}
.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
  transition: opacity 300ms;
}
.flexslider .slides img {
  width: 100%;
  display: block;
  opacity: 1;
  transition: opacity 300ms;
}
.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .flexslider .slides {
  display: block;
}
* html .flexslider .slides {
  height: 1%;
}
.no-js .flexslider .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  position: relative;
  zoom: 1;
}
.flexslider .slides {
  zoom: 1;
}
.flexslider a {
  text-decoration: none;
}
.flexslider ul>li.container-fluid {
  padding: 0 10px;
}
.flexslider .slides img {
  height: auto;
  -moz-user-select: none;
}
.flex-viewport {
  max-height: 2000px;
  transition: all 1s ease;
}
.loading .flex-viewport {
  max-height: 300px;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  *height: 0;
}
.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  overflow: hidden;
  opacity: 1;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease-in-out;
}
.flex-direction-nav a:before {
  /*font-family: "flexslider-icon";*/
  font-size: 40px;
  display: inline-block;
  /*content: '\f001';*/
  color: rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease-in-out;
}
.flex-direction-nav a.flex-next:before {
  /*content: '\f002';*/
}
.flex-direction-nav .flex-prev {
  left: 10px;
}
.flex-direction-nav .flex-next {
  right: 10px;
  text-align: right;
}
.flexslider:hover .flex-direction-nav .flex-prev {
  /*opacity: 0.7;
  left: 10px;*/
}
.flexslider:hover .flex-direction-nav .flex-prev:hover {
  /*opacity: 1;*/
}
.flexslider:hover .flex-direction-nav .flex-next {
  /*opacity: 0.7;
  right: 10px;*/
}
.flexslider:hover .flex-direction-nav .flex-next:hover {
  /*opacity: 1;*/
}
.flex-direction-nav .flex-disabled {
  opacity: 0!important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}
.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}
.flex-pauseplay a:hover {
  opacity: 1;
}
.flex-pauseplay a.flex-play:before {
  content: '\f003';
}
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 10;
  left: 0;
}
.single .flex-control-nav {
  text-align: center;
}
.flex-control-nav li {
  margin: 0 7px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.flex-control-paging li a {
  @include text-indent;
  width: 10px;
  height: 10px;
  display: block;
  overflow: hidden;
  background: transparent;
  cursor: pointer;
  text-indent: -9999px;
  border: 1px solid $brand-primary;
  border-radius: 50%;
}
.flex-control-paging li a:hover {
  background: $brand-primary;
}
.flex-control-paging li a.flex-active {
  background: $brand-primary;
  cursor: default;
}
.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}
.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}
.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .7;
  cursor: pointer;
  -moz-user-select: none;
  transition: all 1s ease;
}
.flex-control-thumbs img:hover {
  opacity: 1;
}
.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

/* ====================================================================================================================
 * TESTIMONIALS
 * ====================================================================================================================*/
.flexslider.testimonials .slides {
  display: flex;
  li {
    align-self: center;
  }
}

.testimonials blockquote {
  padding-right: 12%;
}

.testimonials li > p {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 1em;
  font-family: $font-family-monospace;
}

@media screen and (max-width: 992px) {}
@media screen and (min-width: 992px) {}


/* ====================================================================================================================
 * ARROW CURSOR SLIDER
 * ====================================================================================================================*/
.home .gallery .flex-direction-nav {
  position: absolute;
  right: 0;
  bottom: 51%;
  width: 50%;
  height: 21px;
  margin-top: -10px;
}
.gallery .flex-direction-nav a.flex-prev {
    left: 6.8%;
    background: url("../images/arrow-white.svg") 88% 40% no-repeat;
    transform: rotate(180deg);
    /*cursor: url("../images/arrow-left.png") 0 0, pointer;
    cursor: -webkit-image-set(url("../images/arrow-left.png") 1x, url("../images/arrow-left@2x.png") 2x) 0 0, pointer; */
    text-indent: -99999px;
}
.gallery .flex-direction-nav a.flex-next {
    right: 6.8%;
    background: url("../images/arrow-white.svg") 88% 60% no-repeat;
    /*cursor: url("../images/arrow-right.png") 0 0, pointer;
    cursor: -webkit-image-set(url("../images/arrow-right.png") 1x, url("../images/arrow-right@2x.png") 2x) 0 0, pointer;*/
    text-indent: 99999px;
}
.gallery .flex-direction-nav a:before{
    display: none;
}

/* ====================================================================================================================
 * HOME SLIDER
 * ====================================================================================================================*/
.home .flexslider {
  padding-bottom: 0;
  margin-bottom: 0;
}
.flexslider h1 {
  min-height: 125px;
}
.flexslider h1 span {
  display: block;
  opacity: 0;
  transform: translate(0, -100%);
  transition: transform 0.175s ease-out 0.275s, opacity 0.175s linear 0.275s;
}
.flexslider .flex-init-slide h1 span {
  opacity: 0;
  transform: translate(0, 100%);
}
.flexslider .flex-slide h1 span {
  opacity: 1;
  transform: translate(0, 0%);
}
.flex-slide a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@include media-breakpoint-up(lg) {

  .single-projects .flexslider {
    height: 70vh;
    overflow: hidden;
    padding-bottom: 3em;
    ul, li {
      height: 100%;
    }
    img {
      margin: 0 auto;
      max-width: 66%;
      max-height: 100%;
      width: 66%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  .single-projects .gallery .flex-direction-nav {
    position: absolute;
    right: 0;
    bottom: 51%;
    width: 100%;
    height: 21px;
    margin-top: -10px;
  }
  .single-projects .gallery .flex-direction-nav a.flex-prev {
    left: 10%;
    background: url("../images/arrow-left.svg") 88% 40% no-repeat;
    background-size: contain;
    transform: rotate(0);
    text-indent: -99999px;
    height: 21px;
  }
  .single-projects .gallery .flex-direction-nav a.flex-next {
    right: 10%;
    background: url("../images/arrow-right.svg") 88% 60% no-repeat;
    background-size: contain;
    text-indent: 99999px;
    height: 21px;
  }

}

/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 991px) {

  .flex-control-nav {
    bottom: -0.5em;
    right: 0;
  }

}

@include media-breakpoint-down(md) {

  .single-projects .flexslider {
    margin-left: -6.2%;
    margin-right: -6.2%;
    padding-bottom: 0;
  }

  .home .gallery .flex-direction-nav {
    right: 0;
    bottom: 24%;
    width: 100%;
  }
  .gallery .flex-direction-nav a.flex-prev {
    left: 5.55%;
  }
  .gallery .flex-direction-nav a.flex-next {
    right: 5.55%;
  }

  .flex-control-nav {
    bottom: 10px;
  }
  .flex-control-nav li {
    padding: 0 3px;
  }
  .flex-control-paging li a {
    @include text-indent;
    width: 9px;
    height: 9px;
    border: 1px solid #fff;
  }
  .flex-control-paging li a:hover,
  .flex-control-paging li a.flex-active {
    background: #fff;
  }

}