/* Search form */
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Signup forms */
body .gform_wrapper {
  padding-top: 1.6em;
}
body.page-template-template-contact .gform_wrapper {
  padding-top: 0.25em;
}

body .ginput_container {
  
}

.gfield_required {
  display: none;
}
body .gform_wrapper li.gfield_error input[type=email], 
body .gform_wrapper li.gfield_error input[type=number], 
body .gform_wrapper li.gfield_error input[type=password], 
body .gform_wrapper li.gfield_error input[type=tel], 
body .gform_wrapper li.gfield_error input[type=text], 
body .gform_wrapper li.gfield_error input[type=url], 
body .gform_wrapper li.gfield_error textarea {
  background: 		none;
  border: 			  none !important;
  outline: 			  none !important;
  width:          100%;
}
::-webkit-input-placeholder {
  color: 			inherit;
}
::-moz-placeholder {
  color: 			inherit;
  opacity: 			1;
}
:-ms-input-placeholder {
  color: 			inherit;
}
:-moz-placeholder {
  color: 			inherit;
}

@keyframes blink { 
   50% { border-color: #fff; } 
}

/* Gravity forms */
body .gform_wrapper input[type=email], 
body .gform_wrapper input[type=number], 
body .gform_wrapper input[type=password], 
body .gform_wrapper input[type=tel], 
body .gform_wrapper input[type=text], 
body .gform_wrapper input[type=url] {
  padding: 			  0;
  text-indent: 		0;
  background:     none;
  border:         1px solid transparent;
  outline:        none !important;
  width:          100%;
}
body .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
  padding: 0;
}

body .gform_wrapper .ginput_container_email {
  border-bottom:  1px solid $text-color;
}
body .gform_wrapper .ginput_container_email input:active,
body .gform_wrapper .ginput_container_email input:focus {
  border-left-color: transparent;
}

body .gform_wrapper {
  max-width: 	 100%;
  width: 			 100%;
}

body .gform_wrapper form li {
  padding: 0; 
}

body .gform_wrapper .gform_validation_container,
body .gform_wrapper div.validation_error,
footer .gform_wrapper .gform_footer {
  display: 		none !important;
}

body .gform_wrapper .gform_footer {
  padding: 1em 0 0 0;
  margin: 0;
}
body .gform_wrapper .gform_footer input.button, 
body .gform_wrapper .gform_footer input[type=submit], 
body .gform_wrapper .gform_page_footer input.button, 
body .gform_wrapper .gform_page_footer input[type=submit] {
  border:     none;
  cursor:     pointer;
  padding:    0;
  width:      auto;
  line-height: 1;
}
.gform_wrapper .gform_footer input.button:hover {
  opacity:    0.8;
}
body .gform_wrapper div.validation_error,
body .gform_wrapper li.gfield.gfield_error, 
body .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
  padding:    0;
  border:     none;
  background: none;
}
body .gform_wrapper div.validation_error,
body .gform_wrapper li.gfield.gfield_error .validation_message, 
body .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.8;
}
body .gform_wrapper .top_label .gfield_error input.large, 
body .gform_wrapper .top_label .gfield_error select.large, 
body .gform_wrapper .top_label .gfield_error textarea.textarea {
  width: 100%;
}
body .gform_wrapper li.gfield.gfield_error.gfield_contains_required {
  margin-top: 0;
  margin-bottom: 0 !important;
}
body .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container,
body .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description {
  margin: 5px 0;
  padding: 0 !important;
}

body .gform_confirmation_wrapper p {
  margin-bottom: 0.25em;
}
body .gform_confirmation_wrapper a:hover {
  opacity: 0.8;
}
body footer .gform_confirmation_message {
  margin-top: 1.9%;
  padding: .705em 20px;
}
body .gform_wrapper form li:before {
  content: none;
}


@include media-breakpoint-down(md) {
  
  body .gform_wrapper {
    font-size: 1em;
    line-height: 1.2;
  }

  body.page-template-template-contact .gform_wrapper {
    padding-top: 0.55em;
  }

  label,
  body .gform_wrapper .top_label .gfield_label {
    width: 50%;
  }
  
}

@include media-breakpoint-up(lg) {

  label,
  body .gform_wrapper .top_label .gfield_label {
    display: none;
  }

  body .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    padding: 0 3px;
  }

  body .gform_wrapper .ginput_container_email {
    width: 85%;
  }

  body .gform_wrapper .ginput_container_email input {
    margin: 2px;
    border-left: 1px solid $dark-color;
    animation: blink 2s ease-in-out infinite alternate;
  }

  body .gform_wrapper .gform_footer input.button, 
  body .gform_wrapper .gform_footer input[type=submit], 
  body .gform_wrapper .gform_page_footer input.button, 
  body .gform_wrapper .gform_page_footer input[type=submit] {
    font-size:   1.25em;
  }
  
}