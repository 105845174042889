/* Grid system */
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
  padding: 0 !important;
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}

.container {
  width: 			100%;
  transition: 		width 300ms ease-out;
}

.content {
  padding-top: 12.8%;
}

.home .content,
.single-projects .content,
.page-id-76 .content {
  padding-top: 0;
}

/* Laptop // bootstrap large devices less than 1200px ----------- */
	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-up(lg) {

    .container {
      padding-left:		$container-padding;
      padding-right:	$container-padding;
    }

    .col-md-6 > .container,
    .col-lg-6 > .container,
    #navbarNav > .container {
      padding-left:		calc(#{$container-padding} * 2);
      padding-right:	calc(#{$container-padding} * 2);
    }

    .page-template-template-people .content {
      padding-top: 13.8%;
    }

	}

/* Tablet // bootstrap medium devices less than 992px ----------- */
	@include media-breakpoint-down(md) {

    .container {
      padding-left:		$container-padding;
      padding-right:	$container-padding;
    }

    .content {
      padding-top: 23.5%;
    }

    .page-template-template-people .content {
      padding-top: 26.5%;
    }

    .single-projects .content {
      padding-top: 44px;
      padding-top: calc(34px + 4%);
    }
	
	}	

/* Tablet // bootstrap devices 768px or larger ----------- */
	@include media-breakpoint-up(md) {}

/* Mobile (landscape) // bootstrap small devices less than 768px ----------- */
	@include media-breakpoint-down(sm) {}