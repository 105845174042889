body#tinymce {
  margin: 12px !important;
}

body#tinymce ul li {
  margin-left: 1em !important;
}

body#tinymce ul li:before {
  content: "– ";
  padding-right: 0.25em !important;
  margin-left: -1em !important;
  display: inline-block !important;
}