.navbar {
	min-height: 44px;
	padding: 2% 5.55% 2% 5.55%;
	transition: all 250ms ease-out;

}

.single-post .navbar {
	display: none;
}

.navbar-brand {
	//@include text-indent;
	width: 80px;
	height: 31px;
  color: $brand-primary;
  fill: $brand-primary;
	padding: 0;
	margin: 0;
	text-decoration: none !important;
	border: none !important;
}

.navbar-brand svg {
  max-width: 100%;
  height: auto;
  line-height: 44px;
}

.navbar-brand svg path {
  fill: $brand-primary;
  transition: all 300ms;
}

.logo-green .navbar-brand svg path {
  fill: $brand-primary;
}

.page-id-76.logo-inherit .navbar-brand svg path,
.logo-white .navbar-brand svg path {
  fill: #fff;
}

.logo-orange .navbar-brand svg path {
  fill: #CD6440;
}

.navbar-toggler {
	background-color: $bg-color;
	margin: 0;
  padding: 10px;
	right: 2.7777%;
	border-radius: 50%;
	border: none;
	width: 42px;
	height: 42px;
	cursor: pointer;
  transition: all 0.4s;

	.menu-active & {
    background-color: transparent;
	}
	
	.icon-bar {
	  background: $brand-primary;
	  width: 22px;
	  height: 2px;
	  border-radius: 0;
	  display: block;
	  text-align: center;
	  margin: 0 auto;
    transform-origin: center center;
    transition: all 300ms ease-in-out;

	  .menu-active & {
	  	margin-top: 0 !important;

	  	&.one {
        transform: translateY(2px) rotateZ(45deg);
      }
      &.two {
          width: 0 !important;
      }
      &.three {
        transform: translateY(-2px) rotateZ(-45deg);
      }
	  }
	}	
	.icon-bar + .icon-bar {
      margin-top: 4px;
	}
}

/* Menu */

/* Desktop Menu */
.nav-primary li {
	padding: 0;
}

/* Mobile Menu */
#navbarNav {
	background: #fff3e9;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1020;
	transform: translate3d(100%, 0, 0);
	transition: transform 0.4s;

  .container {
    height: 100%;
  }

  .row {
    margin: 0;
  }
}
.menu-active #navbarNav {
	transform: translate3d(0, 0, 0);
}
.navbarNav {
	opacity: 0;
  padding-top: 8.45em;
	padding-bottom: 0;
  transition: opacity 0.75s ease 0.4s;

	a {
    font-family: $font-family-sans-serif;
		font-size: 1.364em;
	  line-height: 1.133;
    padding: 0.45em 0;
	  display: block;
		color: $brand-primary;
	}

	.current_page_item a {
    color: $brand-primary;
	}

	.menu-active & {
    opacity: 1;
	}
}

/* MEDIA QUERIES */

  @media screen and (pointer: fine) and (min-width: 992px) {

    .navbar-toggler:hover .icon-bar + .icon-bar {
      margin-top: 6px;
      transition: all 0.3s ease-in-out;
    }
    .menu-active .navbar-toggler:hover .icon-bar + .icon-bar {
      margin-top: 4px;
    }

  }

	@include media-breakpoint-up(lg) {

		.navbar {
			padding: 3.6% 5.55% 0 5.55%;
		}

    #navbarNav {
      box-shadow: -5px 0px 75px -10px rgba(0,0,0,0.06);
      left: 50%;
    }

	}

	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-up(xl) {}

	@media (any-hover: hover) {}

/* Mobile (landscape) // bootstrap devices more than 768px ----------- */
	@include media-breakpoint-up(md) {

    .navbar-brand {
      width: 144px;
      height: 44px;
    }
		
	}
	@include media-breakpoint-down(md) {

    body.menu-active {
      overflow: hidden;
			height: 100vh;
			.navbar-brand {
				display: none;
			}
    }

		.single-projects .navbar {
			background: inherit;
		}

		.single-projects.menu-active .navbar {
			background: transparent;
		}

    #navbarNav {
      overflow-y: scroll;
			ul {
				margin-bottom: 0.5em;
			}
			.social {
				font-size: 1.4em;
				a {
					display: inline-block;
				}
			}
    }

		.navbarNav {
			padding-top: 4.9em;
		}

		.navbar-toggler {
			margin-top: 0;
			right: 5.55%;
		}

		.navbar-brand {
			margin-top: 6px;
			width: 72px;
			height: 28px;
		}
		
	}

	/* Mobile // bootstrap devices below 768px ----------- */
	@include media-breakpoint-up(sm) {}

	@include media-breakpoint-down(sm) {}